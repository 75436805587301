import "core-js/modules/es.array.push.js";
import * as local from '@/libs/local';
import * as gongju from '@/libs/gongju';
import Decimal from 'decimal.js';
//import HelloWorld from '@/components/HelloWorld.vue';
import { showToast } from 'vant';
import { showSuccessToast, showFailToast } from 'vant';
import Vue from 'vue';
import { Swipe, SwipeItem } from 'vant';
import { Tabbar, TabbarItem } from 'vant';
import { Grid, GridItem } from 'vant';
import * as api from '@/api';
import { ref, onMounted } from 'vue';
import { Tab, Tabs } from 'vant';
import { Col, Row } from 'vant';
import { Popup } from 'vant';
import { Cell, CellGroup } from 'vant';
import { Dialog } from 'vant';
import { showConfirmDialog } from 'vant';
import { NoticeBar } from 'vant';
import { NavBar } from 'vant';
import { Picker } from 'vant';
import { Empty } from 'vant';
import { BackTop } from 'vant';
import { Notify } from 'vant';
import { Popover } from 'vant';
import { Circle } from 'vant';
import { showDialog } from 'vant';
import 'vant/es/toast/style';
import 'vant/es/dialog/style';
import Header from '../lyout/header.vue';
import Footer from '../lyout/footer.vue';
import Ce from '../lyout/ce.vue';
export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    Ce
  },
  data() {
    return {
      active: 0,
      search_value: '',
      banner: [],
      news: [],
      lang_list: [],
      select_lang: {},
      lang: {},
      cate: [],
      user: {},
      showPopover: false,
      all: [{
        top: -0.7,
        class: "1"
      }],
      count: 10,
      jizi: [],
      text: "7/10",
      chken: {},
      currentRate: 0,
      currentRate1: 0,
      ji: {
        ce1: 0,
        ce2: 100
      },
      chuan: [],
      app_root: api.base(),
      buy: {},
      yx: [],
      re: [],
      gq: [],
      show: false,
      dong: false,
      ref_tag: null
    };
  },
  setup() {
    // 使用ref引用DOM元素  
    const scrollContainer = ref(null);
    function scrollToCenter() {
      if (scrollContainer.value) {
        scrollContainer.value.scrollLeft = 0;
      }
    }

    // 在组件挂载后调用scrollToCenter方法  
    onMounted(() => {
      scrollToCenter();
    });
    return {
      scrollContainer
    };
  },
  created: function () {
    let _this = this;
    let init = 0;

    //  console.log(this.all)
    _this.get_imdex_data();
    this.dong = true;
    setTimeout(() => {
      this.dong = false;
    }, 1500);
  },
  methods: {
    open_c() {
      this.show = true;
    },
    qust() {
      showToast(this.lang.t6);
    },
    onClickLeft() {
      api.chat(0);
    },
    back() {
      window.history.back();
    },
    //切换语言
    select(e) {
      local.saveInfo('setting_lang', e);
      window.location.reload();
    },
    get_imdex_data: function () {
      api.all('/api/index/my_ship1', {}, (err, data) => {
        if (!err && data.code === 1) {
          this.lang_list = data.lang_list;
          this.select_lang = data.select_lang;
          this.lang = data.data.lang;
          this.user = data.data.user;
          this.chuan = data.data.chuan;
          this.yx = data.data.yx;
          this.re = data.data.re;
          this.gq = data.data.gq;
          if (this.yx.length > 0) {
            this.buy = this.yx[0];
          }
        } else {
          console.log(data);
          //  if(data&&'msg' in data){
          //    Toast(data.msg)
          //  }
          //this.block = false
        }
      });
    },
    coop: function () {
      let _this = this;
      _this.$router.push({
        name: 'coop',
        query: {
          id: 1
        }
      });
      this.$nextTick(() => {
        window.scrollTo(0, 0); // 滚动到顶部
      });
    },
    up_img: function (item) {
      this.buy = item;
      this.dong = true;
      setTimeout(() => {
        this.dong = false;
      }, 800);
      const element = document.getElementById("big_w");
      element.scrollLeft = 0;
    },
    to_dao: function () {
      showConfirmDialog({
        title: this.lang.t9,
        confirmButtonText: this.lang.t11,
        cancelButtonText: this.lang.t12,
        message: this.lang.t10
      }).then(() => {
        api.all('/api/index/buy', {
          id: this.buy.id
        }, (err, data) => {
          if (!err && data.code === 1) {
            showSuccessToast(data.msg);
            // this.get_imdex_data()
          } else if (!err && data.code === 0) {
            showToast(data.msg);
          } else {
            console.log(data);
          }
        });
      }).catch(() => {
        // on cancel
      });
    },
    news_list: function () {
      let _this = this;
      _this.$router.push({
        name: 'ac_list',
        query: {
          id: 0
        }
      });
      this.$nextTick(() => {
        window.scrollTo(0, 0); // 滚动到顶部  
      });
    }
  }
};